.custom-week-filter {
    .left-icon-btn, .right-icon-btn, .home-icon-btn {
        min-width: 40px;
        padding: 0.05rem 0.5rem;
        .btn-text {
            font-size: 1.35rem;
        }

        &:focus {
            box-shadow: none !important;
            border-color: transparent !important;
        }
    }
    .custom-week-picker {
        width: 105px;
        padding: 0.365rem 0.5rem;
        .ant-picker-suffix {
            display: none;
        }
    }
}